<template>
	<div :style="{ position: 'relative', width: '100%' }">
		<div :style="{ width: '100%' }" class="mainDiv">
			<div class="subDiv">User Profile</div>
			<div :style="{ padding: '10px' }">
				<div :style="{ marginTop: '10px' }">
					<a-form-item label="Display Name" name="nickName" :rules="[{ required: true, message: 'Please input your username!' }]">
						<a-input v-model:value="formData.nickName" />
					</a-form-item>
					<a-form-item label="Gender" name="gender" :rules="[{ required: true, message: 'Please select your gender!' }]">
						<a-radio-group v-model:value="formData.gender">
							<a-radio value="m">Male</a-radio>
							<a-radio value="f">Female</a-radio>
						</a-radio-group>
					</a-form-item>
					<a-form-item label="Pref cul. regions" name="celebrity_type" :rules="[{ required: true, message: 'Please select your preferred cultural regions!' }]">
						<a-select v-model:value="formData.celebrity_type" disabled>
							<a-select-option value="bollywood">BollyWood</a-select-option>
							<a-select-option value="hollywood">HollyWood</a-select-option>
							<a-select-option value="brazilian">Brazilian</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="Height" name="height">
						<a-input v-model:value="formData.height" type="number" step="0.01" addon-after="cm" />
					</a-form-item>

					<a-form-item label="Jungian Type" name="jungian_type" :rules="[{ required: true, message: 'Please select your jungian type!' }]">
						<a-select v-model:value="formData.jungian_type" :options="jungianType"> </a-select>
					</a-form-item>
				</div>
			</div>
		</div>
		<div :style="{ width: '100%', marginTop: '10px' }" class="mainDiv">
			<div class="subDiv">Short Introduction</div>
			<div :style="{ padding: '10px' }">
				<div v-for="(section, index) in sections" :key="index">
					<div v-if="index > 0" :style="{ fontSize: '20px', display: 'flex', justifyContent: 'end', marginBottom: '13px' }">
						<CloseCircleOutlined @click="removeSection(index)" />
					</div>
					<a-form-item label="Language">
						<a-select v-model:value="section.language" placeholder="Select a language" @change="updateFormData" :disabled="index === 0">
							<a-select-option v-for="language in languageList" :key="language.key" :value="language.key">
								{{ language.language }}
							</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="Profession" :style="{ marginBottom: '8px' }">
						<a-input v-model:value="section.profession" @input="updateFormData" />
					</a-form-item>

					<a-form-item label="Short Intro">
						<a-textarea v-model:value="section.shortIntro" :rows="5" style="width: 100%" @input="updateFormData" />
					</a-form-item>
					<div v-if="index < sections.length - 1" class="section">
						<div class="devider"></div>
					</div>
				</div>
				<a-button type="dashed" block @click="addSection" v-if="formData.celebrity_type === 'hollywood'">
					<PlusOutlined />
					Add Introduction
				</a-button>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
	import { getLanguagesForCelebrity } from "@/services/api/user";
	import { openNotificationWithIcon } from "@/utils";
	import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons-vue";
	import { ref } from "vue";
	const { formData } = defineProps(["formData"]);
	const languageList = ref(<any>[]);
	const sections = ref([
		{
			language: "",
			shortIntro: "",
			profession: "",
		},
	]);

	const jungianType = [
		{ name: "ISTJ", value: "ISTJ" },
		{ name: "ISFJ", value: "ISFJ" },
		{ name: "INFJ", value: "INFJ" },
		{ name: "INTJ", value: "INTJ" },
		{ name: "ISTP", value: "ISTP" },
		{ name: "ISFP", value: "ISFP" },
		{ name: "INFP", value: "INFP" },
		{ name: "INTP", value: "INTP" },
		{ name: "ESTJ", value: "ESTJ" },
		{ name: "ESFJ", value: "ESFJ" },
		{ name: "ENFJ", value: "ENFJ" },
		{ name: "ENTJ", value: "ENTJ" },
		{ name: "ESTP", value: "ESTP" },
		{ name: "ESFP", value: "ESFP" },
		{ name: "ENFP", value: "ENFP" },
		{ name: "ENTP", value: "ENTP" },
	];

	const getLanguages = async () => {
		try {
			const apiRes = await getLanguagesForCelebrity();
			if (!apiRes.valid) return;
			languageList.value = apiRes.data.map(({ key, language }: any) => ({ key, language }));
			const defaultLanguageMap: { [key: string]: string } = {
				bollywood: "EN",
				hollywood: "EN",
				brazilian: "PT",
			};
			const defaultLanguage = defaultLanguageMap[formData.celebrity_type];
			if (defaultLanguage && !sections.value[0].language) {
				const language = languageList.value.find((lng: any) => lng.key === defaultLanguage);
				if (language) sections.value[0].language = language.key;
			}
		} catch (error) {
			console.error("Error fetching languages:", error);
		}
	};

	getLanguages();

	if (Array.isArray(formData?.introduction)) {
		sections.value = formData.introduction.map(({ language, short_intro, profession }: { language: string; short_intro: string; profession: string }) => ({
			language,
			shortIntro: short_intro,
			profession: profession,
		}));
	}

	const addSection = () => {
		if (sections.value.length < languageList.value.length) {
			const newSection = {
				language: "",
				shortIntro: "",
				profession: "",
			};
			sections.value.push(newSection);
		} else {
			openNotificationWithIcon("error", "You cannot add more sections than the available languages.");
		}
	};

	const removeSection = (index: any) => {
		sections.value.splice(index, 1);
		updateFormData();
	};

	const updateFormData = () => {
		const selectedLanguages = sections.value.map((section) => section.language);
		const duplicateLanguage = selectedLanguages.find((lang, index) => lang && selectedLanguages.indexOf(lang) !== index);
		if (duplicateLanguage) {
			openNotificationWithIcon("error", `Language "${duplicateLanguage}" is selected multiple times. Please choose a different language.`);
			return;
		}
		formData.introduction = sections.value.map((section) => ({
			language: section.language,
			short_intro: section.shortIntro,
			profession: section.profession,
		}));
	};
</script>
<style scoped>
	.mainDiv {
		border: 2px solid rgb(190, 190, 190);
		position: relative;
		border-radius: 8px;
		.ant-form-item {
			margin-bottom: 8px !important;
		}
	}

	.subDiv {
		display: flex;
		position: absolute;
		top: -12px;
		background: #ffff;
		margin-left: 20px;
		padding: 0 5px;
		font-weight: 700;
	}

	.section {
		display: flex;
		justify-content: center;
	}

	.devider {
		border-bottom: 1px solid gray;
		width: 89%;
		margin: 9px 0px;
	}
</style>
