import axios, { AxiosRequestConfig } from "axios";
import { BACKEND_BASE_URL, api, httpAuth } from "../.config";
import { toDynamicKey } from "../utils/tokenGenerate";
import { notEmptyString, openNotificationWithIcon } from "@/utils";
import router from "@/router";
import { ColumnsData } from "@/interface/Members.interface";
const Index = {
	init() {
		axios.defaults.baseURL = BACKEND_BASE_URL;
	},

	async post<T>(endpoint: string, data?: any) {
		try {
			const response = await axios.post(endpoint, data, {
				headers: { token: toDynamicKey() },
			});
			return response.data;
		} catch (error: any) {
			throw error;
		}
	},

	async postImage<T>(endpoint: string, data?: any) {
		try {
			const response = await axios.post(endpoint, data, {
				headers: { token: toDynamicKey(), 'Content-Type': 'multipart/form-data' },
			});
			return response.data;
		} catch (error: any) {
			throw error;
		}
	},

	async put<T>(endpoint: string, data?: any) {
		try {
			const response = await axios.put(endpoint, data, {
				headers: { token: toDynamicKey() },
			});
			return response.data;
		} catch (error: any) {
			throw error;
		}
	},
};

export default Index;

export const buildOptions = (isFormData = false, userId = null, callback: any = null) => {
	const { key } = api;
	const options: any = {};
	const headers: any = {};
	const uid: any = notEmptyString(userId) ? userId : "";

	if (isFormData) {
		headers["X-Requested-With"] = "XMLHttpRequest";
		headers["Content-Type"] = "multipart/form-data";
		if (callback instanceof Function) {
			options.onUploadProgress = callback;
		}
	}

	if (httpAuth) {
		if (notEmptyString(httpAuth.user, 3) && notEmptyString(httpAuth.pass, 3)) {
			const credentials = btoa(httpAuth.user + ":" + httpAuth.pass);
			headers.Authorization = "Basic " + credentials;
		}
	}
	if (key) {
		if (key.length > 1) {
			headers.token = toDynamicKey(uid);
		}
	}
	options.headers = headers;
	return options;
};

export const fetchData = async (path: string, deleteMode = false, userId = "") => {
	let result: any = {};
	const func = deleteMode !== true ? axios.get : axios.delete;
	await func(`${api.base}${path}`, buildOptions(false, null, userId))
		.then((res) => {
			result = res;
		})
		.catch((e) => {
			openNotificationWithIcon("error", e?.response?.data?.message);
			result.error = e;
		});
	return result;
};

export const columnsData: ColumnsData[] = [
	{
		title: "Name",
		dataIndex: "nickName",
		key: "nickName",
		visible: true,
		fixed: true,
		category: "userProfile",
		sorter: true,
		customFilterDropdown: true,
		width: 150,
	},
	{
		title: "Email",
		dataIndex: "identifier",
		key: "identifier",
		visible: true,
		category: "userProfile",
		width: 300,
		sorter: true,
		customFilterDropdown: true,
	},
	{
		title: "Age",
		dataIndex: "age",
		key: "age",
		visible: true,
		category: "userProfile",
		customAge: true,
		filterMultiple: true,
		customFilterDropdown: true,
		width: 100,
		sorter: true,
		align: "center",
	},
	{
		title: "Gender",
		dataIndex: "gender",
		key: "gender",
		visible: true,
		category: "userProfile",
		filters: [
			{ text: "Male", value: "m" },
			{ text: "Female", value: "f" },
		],
		filterMultiple: false,
		sorter: true,
		width: 100,
		align: "center",
	},
	{
		title: "Current Location",
		dataIndex: "location",
		key: "location",
		category: "userProfile",
		visible: true,
		align: "center",
		width: 300,
		customFilterDropdown: true,
	},
	{
		title: "Bio",
		dataIndex: "bio",
		key: "bio",
		visible: true,
		category: "userProfile",
		align: "center",
		sorter: true,
		width: 300,
		customFilterDropdown: true,
	},
	{
		title: "Residence",
		dataIndex: "residence",
		key: "residence",
		visible: true,
		category: "userProfile",
		width: 200,
		align: "center",
		customFilterDropdown: true,
	},
	{
		title: "Profession",
		dataIndex: "profession",
		key: "profession",
		visible: true,
		category: "userProfile",
		width: 200,
		align: "center",
		customFilterDropdown: true,
	},
	{
		title: "DOB",
		dataIndex: "dob",
		key: "dob",
		visible: true,
		category: "userProfile",
		sorter: true,
		align: "center",
		dobCalendar: true,
		width: 150,
		customFilterDropdown: true,
		filterMultiple: true,
	},
	{
		title: "TOB",
		dataIndex: "tob",
		key: "tob",
		visible: true,
		width: 150,
		category: "userProfile",
		filterMultiple: true,
		align: "center",
	},
	{
		title: "POB",
		dataIndex: "pob",
		key: "pob",
		category: "userProfile",
		visible: true,
		sorter: false,
		width: 300,
		customFilterDropdown: true,
		filterMultiple: true,
		align: "center",
	},
	{
		title: "User Status",
		dataIndex: "userStatus",
		key: "userStatus",
		visible: true,
		category: "userProfile",
		align: "center",
		filters: [
			// { text: "Incomplete", value: "inCompleted" },
			{ text: "Dormant", value: "dormant" },
			{ text: "Active", value: "active" },
			// { text: "Admin Block", value: "adminBlock" },
		],
		filterMultiple: false,
		width: 180,
	},
	{
		title: "Last Login",
		dataIndex: "login",
		key: "login",
		visible: true,
		category: "accountStatus",
		sorter: true,
		loginCalendar: true,
		customFilterDropdown: true,
		width: 150,
		filterMultiple: true,
		align: "center",
	},
	{
		title: "Last Active",
		dataIndex: "modifiedAt",
		key: "modifiedAt",
		category: "accountStatus",
		visible: true,
		sorter: true,
		align: "center",
		customFilterDropdown: true,
		width: 150,
		filterMultiple: true,
		modifiedAtCalendar: true,
	},
	{
		title: "Joined Date",
		dataIndex: "createdAt",
		key: "createdAt",
		visible: true,
		category: "accountStatus",
		sorter: true,
		align: "center",
		customFilterDropdown: true,
		width: 150,
		filterMultiple: true,
		customCalendar: true,
	},
	{
		title: "Times Reported",
		dataIndex: "reported",
		key: "reported",
		visible: false,
		category: "accountStatus",
		width: 150,
		sorter: false,
		filterMultiple: false,
		align: "center",
	},
	{
		title: "Times Blocked",
		dataIndex: "blocked",
		key: "blocked",
		category: "accountStatus",
		visible: false,
		width: 150,
		filterMultiple: false,
		sorter: false,
		align: "center",
	},
	{
		title: "Wants to meet",
		dataIndex: "wantsToMeet",
		key: "wantsToMeet",
		visible: false,
		category: "userSetting",
		filter: true,
		filters: [
			{ text: "Male", value: "m" },
			{ text: "Female", value: "f" },
		],
		width: 150,
		filterMultiple: false,
		align: "center",
	},
	{
		title: "Age range",
		dataIndex: "ageRange",
		key: "ageRange",
		visible: false,
		width: 150,
		category: "userSetting",
		align: "center",
	},
	{
		title: "Distance Range",
		dataIndex: "distanceRange",
		key: "distanceRange",
		category: "userSetting",
		width: 150,
		visible: false,
		align: "center",
	},
	{
		title: "Purpose",
		dataIndex: "purpose",
		key: "purpose",
		visible: false,
		width: 150,
		category: "userSetting",
		filters: [
			{ text: "Casual Dating", value: "casual_dating" },
			{ text: "Long Term Dating", value: "long_term_dating" },
			{ text: "Networking", value: "networking" },
			{ text: "Friendship", value: "friendship" },
			{ text: "Other", value: "other" },
		],
		filterMultiple: true,
		align: "center",
	},
	{
		title: "Relationship Status",
		dataIndex: "relationshipStatus",
		key: "relationshipStatus",
		align: "center",
		visible: false,
		width: 200,
		category: "userSetting",
		filters: [
			{ text: "Married", value: "married" },
			{ text: "Engaged", value: "engaged" },
			{ text: "Separated", value: "separated" },
			{ text: "Single", value: "single" },
			{ text: "Divorced", value: "divorced" },
		],
		filterMultiple: true,
	},
	{
		title: "BirthChart Style",
		dataIndex: "birthChartStyle",
		key: "birthChartStyle",
		align: "center",
		visible: false,
		width: 150,
		category: "userSetting",
		filters: [
			{ text: "North India", value: "ni" },
			{ text: "South India", value: "si" },
			{ text: "East India", value: "ei" },
			{ text: "Western", value: "w" },
		],
		filterMultiple: true,
	},
	{
		title: "Tropical/Sidereal",
		dataIndex: "tropical_sidereal",
		key: "tropical_sidereal",
		align: "center",
		visible: false,
		category: "userSetting",
		width: 150,
		filters: [
			{ text: "Tropical", value: "t" },
			{ text: "Sidereal", value: "s" },
		],
		filterMultiple: true,
	},
	{
		title: "Notification-Likes",
		dataIndex: "notificationLikes",
		category: "notificationSettings",
		key: "notificationLikes",
		align: "center",
		width: 150,
		visible: false,
		filters: [
			{ text: "Yes", value: true },
			{ text: "No", value: false },
		],
		filterMultiple: true,
	},
	{
		title: "Notification-SuperLikes",
		dataIndex: "notificationSuperLikes",
		category: "notificationSettings",
		key: "notificationSuperLikes",
		align: "center",
		width: 200,
		visible: false,
		filters: [
			{ text: "Yes", value: true },
			{ text: "No", value: false },
		],
		filterMultiple: true,
	},
	{
		title: "Notification-Message",
		dataIndex: "notificationMessage",
		category: "notificationSettings",
		key: "notificationMessage",
		align: "center",
		width: 200,
		visible: false,
		filters: [
			{ text: "Yes", value: true },
			{ text: "No", value: false },
		],
		filterMultiple: true,
	},
	{
		title: "Notifications-Matches",
		dataIndex: "notificationMatches",
		category: "notificationSettings",
		key: "notificationMatches",
		align: "center",
		width: 200,
		visible: false,
		filters: [
			{ text: "Yes", value: true },
			{ text: "No", value: false },
		],
		filterMultiple: true,
	},
	{
		title: "Passes/w",
		dataIndex: "userSwipes",
		category: "swipeActivities",
		key: "userSwipes",
		visible: false,
		width: 150,
		sorter: false,
		align: "center",
	},
	{
		title: "Likes/w",
		dataIndex: "userLikes",
		category: "swipeActivities",
		key: "userLikes",
		visible: false,
		width: 150,
		sorter: false,
		align: "center",
	},
	{
		title: "Super Likes/w",
		dataIndex: "userSuperLikes",
		category: "swipeActivities",
		key: "userSuperLikes",
		visible: false,
		width: 150,
		sorter: false,
		align: "center",
	},
	{
		title: "Matches/w",
		dataIndex: "matchesMade",
		category: "swipeActivities",
		key: "matchesMade",
		width: 150,
		visible: false,
		sorter: false,
		align: "center",
	},
	{
		title: "MatchMeUp/w",
		dataIndex: "matchMeupTimeCount",
		key: "matchMeupTimeCount",
		width: 150,
		visible: false,
		sorter: false,
		align: "center",
	},
	{
		title: "Lucky Times Visit/w",
		dataIndex: "luckyTimeCount",
		key: "luckyTimeCount",
		width: 200,
		visible: false,
		sorter: false,
		align: "center",
	},
	{
		title: "Astro Comp visit/w",
		dataIndex: "astroCompVisitCount",
		key: "astroCompVisitCount",
		width: 200,
		visible: false,
		sorter: false,
		align: "center",
	},
	{
		title: "Jungian Type",
		dataIndex: "jungianType",
		key: "jungianType",
		width: 150,
		visible: false,
		filters: [
			{ text: "ISTJ", value: "ISTJ" },
			{ text: "ISFJ", value: "ISFJ" },
			{ text: "INFJ", value: "INFJ" },
			{ text: "INTJ", value: "INTJ" },
			{ text: "ISTP", value: "ISTP" },
			{ text: "ISFP", value: "ISFP" },
			{ text: "INFP", value: "INFP" },
			{ text: "INTP", value: "INTP" },
			{ text: "ESTJ", value: "ESTJ" },
			{ text: "ESFJ", value: "ESFJ" },
			{ text: "ENFJ", value: "ENFJ" },
			{ text: "ENTJ", value: "ENTJ" },
			{ text: "ESTP", value: "ESTP" },
			{ text: "ESFP", value: "ESFP" },
			{ text: "ENFP", value: "ENFP" },
			{ text: "ENTP", value: "ENTP" },
		],
		align: "center",
		filterMultiple: true,
	},
	{
		title: "Quiz Completed(Y/N)",
		dataIndex: "answeredQuiz",
		key: "answeredQuiz",
		width: 200,
		visible: false,
		align: "center",
	},
	{
		title: "Jungian Comp visit/w",
		dataIndex: "jugnianCompVisitCount",
		key: "jugnianCompVisitCount",
		width: 200,
		visible: false,
		sorter: false,
		align: "center",
	},
	{
		title: "Device Model",
		dataIndex: "device",
		key: "device",
		width: 150,
		visible: false,
		align: "center",
		category: "device",
	},
	{
		title: "App Version",
		dataIndex: "appVersion",
		key: "appVersion",
		width: 200,
		visible: false,
		align: "center",
		category: "device",
	},
	{
		title: "Build Number",
		dataIndex: "build",
		key: "build",
		width: 200,
		visible: false,
		align: "center",
		category: "device",
	},
];