<template>
	<div :style="{ padding: '20px 20px 0px 20px' }">
		<div class="table-operations" v-if="!openDrawer">
			<a-breadcrumb class="heading" separator="/">
				<a-breadcrumb-item :style="{ color: '#44142a' }"><StarOutlined /> Celebrities V2</a-breadcrumb-item>
			</a-breadcrumb>
			<a-form-item label="Pref cul. regions" name="celebrity_type">
				<a-select v-model:value="celebrity_type" @change="handleCelebrityTypeChange">
					<a-select-option value="hollywood">Hollywood</a-select-option>
					<a-select-option value="bollywood">Bollywood</a-select-option>
					<a-select-option value="brazilian">Brazilian</a-select-option>
				</a-select>
			</a-form-item>
			<a-button @click="() => (openDrawer = true)" type="primary" class="clear-filter">Add Celebrities</a-button>
			<a-button @click="clearFilters" type="primary" class="clear-filter">Clear filters</a-button>
			<a-button type="primary"
				><template #icon><DownloadOutlined /></template
			></a-button>
		</div>

		<NewCelebrityAdd v-if="openDrawer" :closeCelebritiesDrawer="closeCelebritiesDrawer" :data="data" :celebrity_type="celebrity_type" />

		<a-table
			v-if="!openDrawer"
			size="middle"
			bordered
			:columns="columns"
			@change="handleChange"
			:data-source="data"
			:pagination="{
				current: pagination.current,
				pageSize: pagination.pageSize,
				total: pagination.total,
				position: ['topRight'],
				size: 'small',
				// showTotal: (total:any, range:any) => `${range[0]}-${range[1]} of ${total}`,
				showTotal: (total:any, range:any) => `${totalPublished} of ${totalCelebrity} Published | 📃 ${range[0]}-${range[1]} of ${total}`,

				showSizeChanger: false, 
			}"
			:loading="loading"
			:scroll="{ x: 1, y: height - 245 }"
			class="tableBody"
		>
			<template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
				<div style="padding: 8px" v-if="!column.customCalendar && column.dataIndex !== 'celebrityStatus'">
					<a-input
						:placeholder="`Search ${column.title}`"
						:value="selectedKeys[0]"
						class="filterInput"
						@change="(user:any) => setSelectedKeys(user.target.value ? [user.target.value] : [])
			"
						@pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
					/>
					<a-button :style="{ width: '90px', marginRight: '8px' }" @click="handleReset(clearFilters, confirm, column.dataIndex)"> Reset </a-button>
					<a-button type="primary" @click="handleSearch(selectedKeys, confirm, column.dataIndex)">
						<template #icon><SearchOutlined /></template>
						Search
					</a-button>
				</div>
				<div style="padding: 8px" v-else-if="column.dataIndex === 'celebrityStatus'">
					<div style="margin-bottom: 10px">
						<a-radio-group v-model:value="selectedKeys[0]" @change="(e: any) => setSelectedKeys([e.target.value])">
							<a-radio style="display: block" value="publish">Publish</a-radio>
							<a-radio style="display: block" value="unpublish">unPublish</a-radio>
						</a-radio-group>
					</div>
					<a-button :style="{ width: '60px', marginRight: '8px', textAlign: 'center' }" @click="handleReset(clearFilters, confirm, column.dataIndex)">Reset</a-button>
					<a-button type="primary" @click="handleSearch(selectedKeys, confirm, column.dataIndex)"> Ok </a-button>
				</div>
				<div style="padding: 8px" v-else>
					<h4>Start Date :</h4>
					<a-date-picker class="filterInput" format="DD-MMM-YYYY" :value="selectedKeys[0]" @change="(e:any)=>{startDate(e),selectedKeys[0]=e}" :disabledDate="disabledDate" />
					<h4>End Date :</h4>
					<a-date-picker class="filterInput" format="DD-MMM-YYYY" :value="selectedKeys[1]" @change="(e:any)=>{endDate(e),selectedKeys[1]=e}" :disabledDate="disabledDate" />
					<a-button :style="{ marginRight: '15px' }" @click="handleReset(clearFilters, confirm, column.dataIndex)"> Reset </a-button>
					<a-button type="primary" :style="{ width: '45%' }" @click="handleSearch(selectedKeys, confirm, column.dataIndex)">Ok </a-button>
				</div>
			</template>
			<template #bodyCell="{ text, column, record }">
				<template v-if="column && column.dataIndex === 'nickName'">
					<a>
						<router-link :to="'/newcelebrities/' + record._id" :data="data">{{ truncateText(text, 25) }}</router-link>
					</a>
				</template>
				<template v-if="column.dataIndex !== 'nickName' && text">
					<div>
						{{ truncateText(text, 25) }}
					</div>
				</template>
				<template v-if="column && column.dataIndex === 'action'">
					<a-button type="danger" @click="handleDelete(record)">
						<DeleteOutlined />
					</a-button>
				</template>
				<template v-if="column && column.dataIndex === 'celebrityStatus'">
					<a-switch v-model:checked="record.status" @change="(value:any) => handleCelebrityPublish(record, value)" />
				</template>
			</template>
		</a-table>
	</div>
</template>
<script lang="ts" setup>
	import { DeleteOutlined, DownloadOutlined, ExclamationCircleOutlined, StarOutlined } from "@ant-design/icons-vue";
	import { Ref, createVNode, ref } from "vue";
	import { logicalPropertiesLinter, Modal, type TableProps } from "ant-design-vue";
	import { SearchOutlined } from "@ant-design/icons-vue";
	import { ColumnsData } from "./../interface/Members.interface";
	import { getNewCelebritiesMap, getCelebrityList, deleteCelebrityApi, celebrityStatusChangeApi } from "../services/api/user";
	import { openNotificationWithIcon, truncateText, useScreenSize } from "../utils/index";
	import dayjs from "dayjs";
	import CelebritiesDetailsAdd from "./CelebritiesDetailsAdd.vue";
	import NewCelebrityAdd from "./NewCelebrityAdd.vue";
	const getCelebrityType = localStorage.getItem("celebrity_type");
	const celebrity_type = ref<string>(getCelebrityType || "hollywood");
	const loading = ref();
	const userList = ref<any[]>([]);
	const data: any = ref([]);
	const openDrawer = ref(false);
	const filtered: any = ref([]);
	const userListFilter: any = ref({
		pageNo: 1,
		pageLimit: 20,
		sortBy: null,
		orderBy: null,
		search: null,
		searchBy: null,
		celebrity_type: celebrity_type.value,
		celebrityStatus: null,
		jungianType: null,
	});
	const pagination = ref({ pageSize: 20, total: 0, current: 0 });
	const totalPublished = ref(0);
	const totalUnpublished = ref(0);
	const totalCelebrity = ref(0);

	const columnsData: ColumnsData[] = [
		{
			title: "Name",
			dataIndex: "nickName",
			key: "nickName",
			visible: true,
			sorter: true,
			fixed: true,
			customFilterDropdown: true,
			width: 200,
		},
		{
			title: "Date of Birth",
			dataIndex: "dob",
			key: "dob",
			visible: true,
			sorter: false,
			align: "center",
			width: 200,
		},
		{
			title: "Place of Birth",
			dataIndex: "pob",
			key: "pob",
			visible: true,
			align: "center",
			width: 200,
			customFilterDropdown: true,
		},
		{
			title: "R",
			dataIndex: "rodden",
			key: "rodden",
			visible: true,
			sorter: true,
			align: "center",
			width: 80,
		},
		{
			title: "16 Type",
			dataIndex: "jungian_type",
			key: "jungian_type",
			visible: true,
			align: "center",
			filters: [
				{ text: "ISTJ", value: "ISTJ" },
				{ text: "ISFJ", value: "ISFJ" },
				{ text: "INFJ", value: "INFJ" },
				{ text: "INTJ", value: "INTJ" },
				{ text: "ISTP", value: "ISTP" },
				{ text: "ISFP", value: "ISFP" },
				{ text: "INFP", value: "INFP" },
				{ text: "INTP", value: "INTP" },
				{ text: "ESTJ", value: "ESTJ" },
				{ text: "ESFJ", value: "ESFJ" },
				{ text: "ENFJ", value: "ENFJ" },
				{ text: "ENTJ", value: "ENTJ" },
				{ text: "ESTP", value: "ESTP" },
				{ text: "ESFP", value: "ESFP" },
				{ text: "ENFP", value: "ENFP" },
				{ text: "ENTP", value: "ENTP" },
			],
			width: 110,
		},
		{
			title: "Profession",
			dataIndex: "profession",
			key: "profession",
			visible: true,
			customFilterDropdown: true,
			align: "center",
			width: 150,
		},
		{
			title: "Height",
			dataIndex: "height",
			key: "height",
			visible: true,
			align: "center",
			width: 100,
		},
		{
			title: "Has Intro",
			dataIndex: "hasIntro",
			key: "hasIntro",
			visible: true,
			align: "center",
			width: 100,
		},
		// {
		// 	title: "Region",
		// 	dataIndex: "celebrity_type",
		// 	key: "celebrity_type",
		// 	visible: true,
		// 	align: "center",
		// 	width: 120,
		// },
		{
			title: "Creation Dt",
			dataIndex: "createdAt",
			key: "createdAt",
			visible: true,
			sorter: true,
			customFilterDropdown: true,
			customCalendar: true,
			align: "center",
			width: 200,
		},
		{
			title: "Status",
			dataIndex: "celebrityStatus",
			key: "celebrityStatus",
			width: 100,
			customFilterDropdown: true,
		},
		{
			title: "Action",
			dataIndex: "action",
			key: "action",
			visible: true,
			align: "center",
			width: 100,
		},
	];

	const columns: Ref<ColumnsData[]> = ref<ColumnsData[]>(columnsData);
	const { height } = useScreenSize();

	const handleChange: TableProps["onChange"] = async (pagination, filters: any, sorter: any) => {
		if (filters.jungianType) {
			userListFilter.value.jungianType = filters.jungianType.join(",");
		}
		userListFilter.value.pageNo = pagination.current;
		userListFilter.value.pageLimit = pagination.pageSize;
		userListFilter.value.orderBy = sorter.order === "ascend" ? "asc" : sorter.order === "descend" ? "desc" : null;
		userListFilter.value.sortBy = sorter.order === undefined ? null : sorter.field;
		fetchData();
	};

	const clearFilters = async () => {
		userListFilter.value = {
			pageNo: 1,
			pageLimit: 20,
			celebrity_type: celebrity_type.value,
		};
		userListFilter.value = {
			...userListFilter.value,
			pageNo: 1,
			celebrity_type: celebrity_type.value,
			search: null,
			searchBy: null,
		};
		localStorage.setItem("celebrity_type", celebrity_type.value);
		fetchData();
	};

	const handleSearch = async (selectedKeys: string[], confirm: () => void, dataIndex: string) => {
		confirm();
		if (dataIndex === "celebrityStatus") {
			const status = selectedKeys[0];
			userListFilter.value.celebrityStatus = status || null;
		} else if (dataIndex === "nickName" || dataIndex === "profession" || dataIndex === "celebrity_type" || dataIndex === "pob") {
			userListFilter.value.search = selectedKeys[0] || null;
			userListFilter.value.searchBy = dataIndex;
		} else if (dataIndex === "jungian_type") {
			userListFilter.value.jungianType = selectedKeys.length > 1 ? selectedKeys.join(",") : null; // Update for jungian_type
		}
		fetchData();
	};

	const handleReset = async (clearFilters: (arg0: { confirm: boolean }) => void, confirm: () => void, dataIndex: string) => {
		confirm();
		clearFilters({ confirm: true });
		if (dataIndex == "createdAt") {
			userListFilter.value.createdEndDate = null;
			userListFilter.value.createdStartDate = null;
		}
		userListFilter.value.search = "";
		userListFilter.value.searchBy = "";
		// await localStorage.setItem("celebrityListFilter", JSON.stringify({ ...userListFilter.value }));
		setTimeout(() => {
			fetchData();
		}, 1000);
	};

	const disabledDate = (date: any) => {
		return dayjs(date).isAfter(dayjs().add(0, "days"), "day");
	};

	const startDate = (e: any) => {
		userListFilter.value.createdStartDate = e.format(`YYYY-MM-DDT00:00:00.SSS[Z]`);
	};

	const endDate = (e: any) => {
		userListFilter.value.createdEndDate = e.format("YYYY-MM-DDT11:59:00.SSS[Z]");
	};

	const handleCelebrityTypeChange = (e: any) => {
		if (!e) {
			userListFilter.value.celebrity_type = "hollywood";
		}
		userListFilter.value.celebrity_type = e;
		localStorage.setItem("celebrity_type", e);
		fetchData();
	};

	const handleDelete = (record: any) => {
		Modal.confirm({
			title: "Are you sure you want to delete this celebrity?",
			icon: createVNode(ExclamationCircleOutlined),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			async onOk() {
				try {
					const result = await deleteCelebrityApi(record._id);
					if (result.valid) {
						fetchData();
					} else {
						openNotificationWithIcon("error", "failed to Delete");
					}
				} catch (error) {
					console.error("Error during deletion:", error);
				}
			},
		});
	};

	const fetchData = async () => {
		try {
			loading.value = true;
			const apiRes = await getCelebrityList(userListFilter.value);
			userList.value = apiRes?.item;
			totalUnpublished.value = apiRes?.totalUnPublishCelebrity;
			totalPublished.value = apiRes?.totalPublishCelebrity;
			totalCelebrity.value = apiRes?.totalCelebrity;
			data.value = await getNewCelebritiesMap(userList.value);
			data.value.forEach((item: any) => {
				item.status = item.status === "publish";
			});
			pagination.value.pageSize = Number(apiRes?.pagination.pageLimit);
			pagination.value.current = Number(apiRes?.pagination.pageNo);
			pagination.value.total = Number(apiRes?.pagination.totalUser);
			loading.value = false;
		} catch (error) {
			loading.value = false;
		}
	};
	fetchData();

	const handleCelebrityPublish = async (record: any, value: boolean) => {
		try {
			loading.value = true;
			const statusValue = value ? "publish" : "unPublish";
			const payload = { status: statusValue };
			await celebrityStatusChangeApi(record._id, payload);
			if (value) {
				totalPublished.value += 1;
				totalUnpublished.value -= 1;
			} else {
				totalPublished.value -= 1;
				totalUnpublished.value += 1;
			}
			loading.value = false;
		} catch (error) {
			console.log(error);
			openNotificationWithIcon("error", "Failed to update status");
		}
	};

	const closeCelebritiesDrawer = () => {
		openDrawer.value = false;
		fetchData();
	};
</script>
<style scoped>
	.table-operations {
		display: flex;
		justify-content: flex-end;
		gap: 8px;
	}

	.table-operations > button {
		margin-left: 8px;
	}

	.heading {
		font-size: 16px;
		font-weight: 600;
		margin-right: auto;
		color: #44142a !important;
	}

	.filterInput {
		margin-bottom: 8px;
		display: block;
	}
</style>
