import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import DashboardView from "@/views/DashboardView.vue";
import membersView from "@/views/MembersView.vue";
import reportedView from "@/views/ReportedView.vue";
import celebritiesView from "@/views/CelebritiesView.vue";
import adminView from "@/views/AdminView.vue";
import loginView from "@/views/LoginView.vue";
import feedbackView from "@/views/FeedbackView.vue";
import deletedView from "@/views/DeletedView.vue";
import analyticsView from "@/views/AnalyticsView.vue";
import astroSettingView from "@/views/AstroSettingView.vue";
import MembersDetailsView from "@/views/MembersDetailsView.vue";
import AdminDetailsView from "@/views/AdminDetailsView.vue";
import celebritiesDetailsView from "@/views/CelebritiesDetailsView.vue";
import ReportedDetailsView from "@/views/ReportedDetailsView.vue";
import FeedbackDetailsView from "@/views/FeedbackDetailsView.vue";
import SinglePairView from "@/views/SinglePairView.vue"
import MultiplePairView from "@/views/MultiplePairView.vue"
import SettingView from "@/views/SettingView.vue";
import AppSettings from "@/views/AppSettings.vue";
import ForceUpdateView from "@/views/ForceUpdateView.vue";
import IpWhiteList from "@/views/IpWhiteList.vue";
import Languages from "@/views/Languages.vue";
import PushNotification from "@/views/PushNotification.vue";
import NotificationList from "@/views/NotificationList.vue";
import IncomplateProfileList from "@/views/IncomplateProfileList.vue";
import Profiles from "@/views/Profiles.vue";
import NewCelebrityView from "@/views/NewCelebrityView.vue";
import NewCelebrityDetailsView from "@/views/NewCelebrityDetailsView.vue";


const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "home",
		component: DashboardView,
		meta: { requiresAuth: true }
	},
	{
		path: "/login",
		name: "login",
		component: loginView,
	},
	{
		path: "/members",
		name: "members",
		component: membersView,
		props: true,
		meta: { requiresAuth: true }
	},
	{
		path: "/profile",
		name: "profile",
		component: Profiles,
		props: true,
		meta: { requiresAuth: true }
	},
	{
		path: "/report/:id",
		name: "reportDetailsView",
		component: MembersDetailsView,
		props: true,
		meta: { requiresAuth: true }
	},
	{
		path: "/profile/:id",
		name: "profileDetailsView",
		component: MembersDetailsView,
		props: true,
		meta: { requiresAuth: true }
	},
	{
		path: "/members/:id",
		name: "MembersDetailsView",
		component: MembersDetailsView,
		props: true,
		meta: { requiresAuth: true }
	},
	{
		path: "/celebrities",
		name: "celebrities",
		component: celebritiesView,
		meta: { requiresAuth: true }
	},
	{
		path: "/celebrities/:id",
		name: "CelebritiesDetailsView",
		component: celebritiesDetailsView,
		meta: { requiresAuth: true }
	},
	{
		path: "/newcelebrities",
		name: "newcelebrities",
		component: NewCelebrityView,
		meta: { requiresAuth: true }
	},
	{
		path: "/newcelebrities/:id",
		name: "NewCelebrityDetailsView",
		component: NewCelebrityDetailsView,
		meta: { requiresAuth: true }
	},
	{
		path: "/admin",
		name: "admin",
		component: adminView,
		meta: { requiresAuth: true }
	},
	{
		path: "/admin/:id",
		name: "adminDetailsView",
		component: AdminDetailsView,
		meta: { requiresAuth: true }
	},
	{
		path: "/reported",
		name: "reported",
		component: reportedView,
		meta: { requiresAuth: true }
	},
	{
		path: "/reported/:id",
		name: "reportedDetailsView",
		component: ReportedDetailsView,
		meta: { requiresAuth: true }
	},
	{
		path: "/feedback",
		name: "feedback",
		component: feedbackView,
		meta: { requiresAuth: true }
	},
	{
		path: "/feedback/:id",
		name: "feedbackDetailsView",
		component: FeedbackDetailsView,
		meta: { requiresAuth: true }
	},
	{
		path: "/deleted",
		name: "deleted",
		component: deletedView,
		meta: { requiresAuth: true }
	},
	{
		path: "/analytics",
		name: "analytics",
		component: analyticsView,
		meta: { requiresAuth: true }
	},
	{
		path: "/synastry",
		name: "synastry",
		component: astroSettingView,
		meta: { requiresAuth: true }
	},
	{
		path: "/multiPair",
		name: "multiPair",
		component: MultiplePairView,
		meta: { requiresAuth: true }
	},
	{
		path: "/singlePair/:id",
		name: "singlePair",
		component: SinglePairView,
		meta: { requiresAuth: true }
	},
	{
		path: "/appsettings",
		name: "/appsettings",
		component: AppSettings,
		meta: { requiresAuth: true }
	}, {
		path: "/forceUpadte",
		name: "/forceUpadte",
		component: ForceUpdateView,
		meta: { requiresAuth: true }
	},
	{
		path: "/ipWhitelist",
		name: "/ipWhitelist",
		component: IpWhiteList,
		meta: { requiresAuth: true }
	}, {
		path: "/languages",
		name: "/languages",
		component: Languages,
		meta: { requiresAuth: true }
	}, {
		path: "/push-notification",
		name: "push-notification",
		component: PushNotification,
		meta: { requiresAuth: true }
	}, {
		path: "/notification-list",
		name: "notification-list",
		component: NotificationList,
		meta: { requiresAuth: true }
	}, {
		path: "/incomplete-profile",
		name: "incomplete-profile",
		component: IncomplateProfileList,
		meta: { requiresAuth: true }
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

router.beforeEach((to, from, next) => {
	const isAuthenticated = localStorage.getItem("user") !== null;

	// Check if the route requires authentication and the user is not authenticated
	if (to.meta.requiresAuth && !isAuthenticated) {
		// Redirect to the login page with the original requested route as a query parameter
		next({ name: "login", query: { redirect: to.fullPath } });
	} else {
		next(); // Proceed to the requested route
	}
});

export default router;
