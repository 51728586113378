<template>
	<a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol" layout="horizontal" @finish="onFinish" @finishFailed="onFinishFailed" autocomplete="off">
		<div :style="{ fontSize: '25px', margin: '10px 10px 15px 10px', display: 'flex', justifyContent: 'space-between' }">
			<a-breadcrumb class="heading" separator="|">
				<a-breadcrumb-item :style="{ color: '#44142a', cursor: 'pointer', fontSize: '16px', fontWeight: '700' }" @click="closeCelebritiesDrawer"> <StarOutlined /> Celebrities </a-breadcrumb-item>
			</a-breadcrumb>
			<div :style="{ display: 'flex', justifyContent: 'center', gap: '13px', marginRight: '20px' }">
				<a-form-item>
					<a-button type="primary" html-type="submit" :style="{ width: '100px' }">Submit</a-button>
				</a-form-item>
				<a-form-item>
					<a-button @click="closeCelebritiesDrawer" :style="{ width: '100px' }">cancel</a-button>
				</a-form-item>
			</div>
		</div>
		<div :style="{ display: 'grid', justifyContent: 'center', marginTop: '20px' }">
			<div :style="{ display: 'flex', padding: '5px' }">
				<NewCelebritiesProfile :formData="formState" />
				<NewCelebritiesNotification :deleteFile="deleteFile" :newUploads="newUploads" :formData="formState" />
			</div>
		</div>
	</a-form>
</template>

<script lang="ts" setup>
	import { StarOutlined } from "@ant-design/icons-vue";
	import { reactive, ref } from "vue";
	import { addCelebrity, uploadImageS3, capitalizeFirstLetter } from "@/services/api/user";
	import { openNotificationWithIcon } from "@/utils";
	import dayjs from "dayjs";
	import router from "@/router";
	import NewCelebritiesProfile from "@/components/NewCelebritiesDetails/NewCelebritiesProfile.vue";
	import NewCelebritiesNotification from "@/components/NewCelebritiesDetails/NewCelebritiesNotification.vue";

	const { closeCelebritiesDrawer, celebrity_type } = defineProps(["closeCelebritiesDrawer", "data", "celebrity_type"]);

	const labelCol = { style: { width: "200px" } };
	const wrapperCol = { span: 14 };
	const newUploads = ref<string[]>([]);
	const deleteFile = ref<string[]>([]);
	const formState = reactive({
		nickName: "",
		gender: "",
		geo: "",
		dob: null,
		tob: null,
		celebrity_type: celebrity_type,
		jungian_type: "",
		rodden: "",
		pob: "",
		height: "",
		profession: "",
		credit: "",
		celebrity_profiles: [
			{
				type: "",
				url: "",
				credit: "",
			},
		],
		introduction: [
			{
				language: "",
				short_intro: "",
				profession: "",
			},
		],
	});

	const onFinish = async () => {
		try {
			if (!newUploads.value.length) {
				openNotificationWithIcon("error", "No image uploaded");
				return;
			}
			const hasIncompleteIntro = formState.introduction.some((item) => !item.language || !item.short_intro || !item.profession);
			if (hasIncompleteIntro) {
				openNotificationWithIcon("error", "Language, description, and profession are required for each introduction entry.");
				return;
			}
			console.log(newUploads.value[0], 80);
			const imageUpload = await uploadImageS3("celebrity", newUploads.value[0]);
			if (imageUpload?.data.url) {
				const uploadedImageUrl = imageUpload.data.url;
				formState.celebrity_profiles = formState.celebrity_profiles.length
					? [
							{
								type: "image",
								url: uploadedImageUrl,
								credit: formState.credit,
							},
					  ]
					: [];
				const dob = `${dayjs(formState.dob).format("YYYY-MM-DD")}T${dayjs(formState.tob).format("HH:mm:ss.SSS[Z]")}`;
				const [lat, lng] = formState.geo.split(" ").map(parseFloat);
				const introductionData = formState.introduction.map((item) => ({
					language: item.language,
					short_intro: item.short_intro,
					profession: item.profession,
				}));
				const dataForChart = {
					nickName: capitalizeFirstLetter(formState.nickName.trim()),
					gender: formState.gender,
					geo: { lat, lng },
					dob,
					celebrity_type: formState.celebrity_type,
					jungian_type: formState.jungian_type,
					rodden: formState.rodden,
					pob: formState.pob,
					height: formState.height,
					profession: formState.profession.trim(),
					celebrity_profiles: formState.celebrity_profiles[0],
					introduction: introductionData,
				};
				const isEnglishExist = dataForChart.introduction.some((item) => item.language === "EN");
				if (!isEnglishExist && formState.celebrity_type === "hollywood") {
					return openNotificationWithIcon("error", "An introduction to the English language is required.");
				}
				const saveData = await addCelebrity(dataForChart);

				if (saveData?.valid) {
					openNotificationWithIcon("success", "Celebrity added successfully");
					router.back();
				}
			} else {
				openNotificationWithIcon("error", "Failed to upload image");
			}
		} catch (error) {
			console.error("Error in onFinish function:", error);
			openNotificationWithIcon("error", "An unexpected error occurred");
		}
	};

	const onFinishFailed = (errorInfo: any) => {
		console.log("Validation failed:", errorInfo);
	};
</script>
