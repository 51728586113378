<template>
	<a-form v-if="!loading" :model="formData" :label-col="labelCol" :wrapper-col="wrapperCol" layout="horizontal" :disabled="componentDisabled" @finish="updateCelebrityDetails(formData._id)" @finishFailed="onFinishFailed">
		<div v-if="!loading" :style="{ fontSize: '25px', margin: '10px 10px 15px 10px', display: 'flex', justifyContent: 'space-between' }">
			<a-breadcrumb class="heading" separator="|">
				<a-breadcrumb-item :style="{ color: '#44142a', cursor: 'pointer', fontSize: '16px', fontWeight: '700' }" @click="closeDetails"><TeamOutlined /> Celebrities</a-breadcrumb-item>
				<a-breadcrumb-item :style="{ fontSize: '16px', fontWeight: '700' }">{{ formData._id }}</a-breadcrumb-item>
			</a-breadcrumb>
			<div :style="{ display: 'flex', justifyContent: 'center', gap: '13px', marginRight: '20px' }">
				<a-button html-type="submit" type="primary" :style="{ width: '100px' }">Save</a-button>
				<a-button @click="closeDetails" :style="{ width: '100px' }">cancel</a-button>
				<!-- <CloseCircleOutlined :style="{}" @click="closeDetails" /> -->
			</div>
		</div>
		<div v-if="loading" class="loading-wrapper">
			<a-spin />
		</div>
		<div :style="{ display: 'grid', justifyContent: 'center', marginTop: '70px' }">
			<div :style="{ display: 'flex', padding: '5px' }">
				<NewCelebritiesProfile :formData="formData" />
				<NewCelebritiesNotification :newUploads="newUploads" :deleteFile="deleteFile" :formData="formData" />
			</div>
		</div>
	</a-form>
</template>

<script lang="ts" setup>
	import { CloseCircleOutlined, TeamOutlined } from "@ant-design/icons-vue";
	import { ref } from "vue";
	import CelebritiesProfile from "@/components/CelebritiesDetails/CelebritiesProfile.vue";
	import CelebritiesNotification from "@/components/CelebritiesDetails/CelebritiesNotification.vue";
	import { addCelebrity, capitalizeFirstLetter, deleteImage, getParticularCelebrityMap, getPerticularCelebrity, getUser, getUserMap, updateCelebrity, uploadImage, uploadImageS3, userSample } from "@/services/api/user";
	import router from "@/router";
	import { openNotificationWithIcon } from "@/utils";
	import dayjs from "dayjs";
	import { useRouter } from "vue-router";
	import NewCelebritiesProfile from "@/components/NewCelebritiesDetails/NewCelebritiesProfile.vue";
	import NewCelebritiesNotification from "@/components/NewCelebritiesDetails/NewCelebritiesNotification.vue";

	const componentDisabled = ref(false);
	const loading = ref(false);
	const user = ref(userSample);
	const formData = ref<any>({});
	const labelCol = { style: { width: "130px" } };
	const wrapperCol = { span: 14 };
	const newUploads = ref([]);
	const deleteFile = ref([]);

	const closeDetails = async () => {
		router.back();
	};

	const fetchData = async () => {
		try {
			loading.value = true;
			const id: any = router.currentRoute.value.params.id;
			if (!id) {
				return;
			}
			const apiRes: any = await getPerticularCelebrity(id);
			user.value = await getParticularCelebrityMap(apiRes?.item);
			formData.value = user.value;
			loading.value = false;
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};
	fetchData();

	const updateCelebrityDetails = async (userID: string) => {
		try {
			if (newUploads.value.length > 0) {
				const uploadResponse = await uploadImageS3("celebrity", newUploads.value[0]);
				if (uploadResponse.valid) {
					updateProfileData(uploadResponse.data.url);
				}
			}
			if (formData.value.celebrity_profiles.length === 0) {
				openNotificationWithIcon("error", "Please Upload the Image ");
			}
			const saveResponse = await saveCelebrityData(userID);
			const notificationType = saveResponse.valid ? "success" : "error";
			const notificationMessage = saveResponse.valid ? "Celebrity updated successfully" : "Failed to update Celebrity";
			openNotificationWithIcon(notificationType, notificationMessage);
			router.push("/newcelebrities");
		} catch (error) {
			console.error("Error updating celebrity details:", error);
		}
	};

	const updateProfileData = (url: string) => {
		if (!formData.value.celebrity_profiles || !Array.isArray(formData.value.celebrity_profiles)) {
			formData.value.celebrity_profiles = [];
		}
		if (formData.value.celebrity_profiles.length === 0) {
			formData.value.celebrity_profiles.push({ type: "image", url });
		} else {
			formData.value.celebrity_profiles[0].type = "image";
			formData.value.celebrity_profiles[0].url = url;
		}
	};
	const saveCelebrityData = async (userID: string) => {
		const dob = `${dayjs(formData.value.dob).format("YYYY-MM-DD")}T${dayjs(formData.value.tob).format("HH:mm:ss.SSS[Z]")}`;
		const [lat, lng] = formData.value.geo.split(",").map(parseFloat);
		const introductionData = formData.value.introduction.map((item: any) => ({
			language: item.language,
			short_intro: item.short_intro,
			profession: item.profession,
		}));
		const dataForChart = {
			nickName: capitalizeFirstLetter(formData.value.nickName),
			gender: formData.value.gender,
			user: formData.value._id,
			geo: { lat, lng: lng },
			dob,
			celebrity_type: formData.value.celebrity_type,
			jungian_type: formData.value.jungian_type,
			rodden: formData.value.rodden,
			pob: formData.value.pob,
			height: formData.value.height,
			profession: formData.value.profession,
			celebrity_profiles: formData.value.celebrity_profiles[0],

			introduction: introductionData,
		};
		const isEnglishExist = dataForChart.introduction.some((item: any) => item.language === "EN");
		if (!isEnglishExist && formData.value.celebrity_type === "hollywood") {
			return openNotificationWithIcon("error", "An introduction to the English language is required.");
		}

		const hasIncompleteIntro = formData.value.introduction.some((item: any) => !item.language || !item.short_intro || !item.profession);
		if (hasIncompleteIntro) {
			openNotificationWithIcon("error", "Both language and description are required for each introduction entry.");
			return;
		}

		return await updateCelebrity(userID, dataForChart);
	};

	const onFinishFailed = (errorInfo: any) => {
		console.log("Validation failed:", errorInfo);
	};
</script>
<style scoped>
	.loading-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100vh;
	}
</style>
