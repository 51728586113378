<template>
	<div :style="{ position: 'relative', marginLeft: '10px', width: '100%' }">
		<div :style="{ width: '100%' }" class="mainDiv">
			<div class="subDiv">Birth Details</div>
			<div :style="{ padding: '10px' }">
				<div :style="{ marginTop: '10px' }">
					<a-form-item label="Date of birth" :style="{ marginBottom: '10px' }" name="dob" :rules="[{ required: true, message: 'Please select your date of birth!' }]">
						<a-date-picker style="width: 100%" v-model:value="formData.dob" format="DD-MMM-YYYY" />
					</a-form-item>
					<a-form-item label="Time of birth" :style="{ marginBottom: '10px' }" name="tob" :rules="[{ required: true, message: 'Please select your time of birth!' }]">
						<a-time-picker style="width: 100%" v-model:value="formData.tob" />
					</a-form-item>
					<a-form-item label="Place of birth" name="pob" :style="{ marginBottom: '10px' }" :rules="[{ required: true, message: 'Please select your place of birth!' }]">
						<a-select v-model:value="formData.pob" allow-clear show-search placeholder="Search a place" @select="select" :options="locationItems" @search="search">
							<template v-slot:notFoundContent>
								<Spin size="small" v-if="loading" />
								<Empty :image="Empty.PRESENTED_IMAGE_SIMPLE" v-else />
							</template>
						</a-select>
					</a-form-item>
					<a-form-item label="Co-ordinates" :style="{ marginBottom: '10px' }" name="geo" :rules="[{ required: true, message: 'Please input your co-ordinates!' }]">
						<a-input v-model:value="formData.geo" disabled />
					</a-form-item>
					<a-form-item label="Rodden" :style="{ marginBottom: '8px' }" name="rodden" :rules="[{ required: true, message: 'Please input your rodden!' }]">
						<a-select v-model:value="formData.rodden">
							<a-select-option value="AA">AA</a-select-option>
							<a-select-option value="A">A</a-select-option>
							<a-select-option value="B">B</a-select-option>
							<a-select-option value="C">C</a-select-option>
							<a-select-option value="X">X</a-select-option>
							<a-select-option value="XX">XX</a-select-option>
							<a-select-option value="DD">DD</a-select-option>
						</a-select>
					</a-form-item>
				</div>
			</div>
		</div>
		<div :style="{ width: '100%', marginTop: '10px' }" class="mainDiv">
			<div class="subDiv">Image</div>
			<div :style="{ padding: '10px' }">
				<div :style="{ marginTop: '10px' }">
					<a-form-item label="Profile Images" name="celebrity_profiles">
						<CelebrityImageUpload :deleteFile="deleteFile" :formData="formData" :newUploads="newUploads" />
					</a-form-item>
					<a-form-item label="Credit" name="credit">
						<a-textarea v-model:value="formData.celebrity_profiles[0].credit" :rows="5" />
					</a-form-item>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
	import { ref } from "vue";
	import { Spin, Empty } from "ant-design-vue/es";
	import { getLocationApi, getGeoCordApi } from "../../services/api/auth";
	import CelebrityImageUpload from "@/views/CelebrityImageUpload.vue";

	const { formData, newUploads, deleteFile } = defineProps(["formData", "newUploads", "deleteFile"]);
	const locationItems: any = ref([]);
	const loading = ref(false);
	if (typeof formData.geo === "object" && formData.geo !== null) {
		if (!formData.geo || !formData.geo.lat.length || !formData.geo.lng.length) {
			formData.geo = "";
		}
	} else if (typeof formData.geo === "string") {
		formData.geo = formData?.geo;
	}

	const select = async (index: number, record: any) => {
		try {
			const res = await getGeoCordApi(record.label);
			const firstResult = res?.data?.results[0];
			const latitude = firstResult.geometry.location.lat;
			const longitude = firstResult.geometry.location.lng;
			formData.geo = `${latitude.toFixed(4)} ${longitude.toFixed(4)}`;
			const latLang = formData.geo.split(" ");
			formData.geo = { lat: latLang[0], lng: latLang[1] };
			formData.geo = `${latitude.toFixed(4)} ${longitude.toFixed(4)}`;
		} catch (error) {
			console.log(error);
		}
	};

	const locationList = async (input: string) => {
		try {
			if (input) {
				const res = await getLocationApi(input);
				const locationData = res?.data?.predictions;
				locationItems.value = locationData.map((e: any, i: number) => ({
					label: e.description,
					value: e.description,
					key: i,
				}));
				loading.value = false;
			}
		} catch (error) {
			loading.value = false;
			console.log(error);
		}
	};

	const search = (input: string) => {
		loading.value = true;
		setTimeout(() => {
			locationList(input);
		}, 1000);
	};
</script>
<style scoped>
	.mainDiv {
		border: 2px solid rgb(190, 190, 190);
		position: relative;
		border-radius: 8px;
		.ant-form-item {
			margin-bottom: 8px !important;
		}
	}
	.subDiv {
		display: flex;
		position: absolute;
		top: -12px;
		background: #ffff;
		margin-left: 20px;
		padding: 0 5px;
		font-weight: 700;
	}
</style>
