<template>
	<div>
		<a-upload class="clearfix" v-model:file-list="fileList" list-type="picture-card" :remove="handleRemove" :before-upload="beforeUpload" @preview="handlePreview" accept="image/*">
			<div v-if="fileList && fileList.length < 1">
				<plus-outlined />
				<div style="margin-top: 8px">Select File</div>
			</div>
		</a-upload>
		<a-modal :open="previewVisible" :title="previewTitle" :footer="null" @cancel="handleCancel">
			<img alt="example" style="width: 100%" :src="previewImage" />
		</a-modal>
	</div>
</template>

<script lang="ts" setup>
	import { ref } from "vue";
	import { PlusOutlined } from "@ant-design/icons-vue";
	const previewImage = ref("");
	const previewTitle = ref("");
	const fileList: any = ref([]);
	const previewVisible = ref(false);
	const { formData, newUploads, deleteFile } = defineProps(["formData", "newUploads", "deleteFile"]);

	function getBase64(file: File) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	}
	if (Array.isArray(formData?.celebrity_profiles)) {
		formData?.celebrity_profiles.forEach((element: any, index: number) => {
			const fullName = element.url.substring(element.url.lastIndexOf("/") + 1);
			const name = fullName.split("-").slice(-1)[0];
			if (element.url && element.url != " ") {
				fileList.value.push({
					uid: index,
					name: name,
					url: element.url,
				});
			}
		});
	}

	const handleCancel = () => {
		previewVisible.value = false;
		previewTitle.value = "";
	};

	const handlePreview = async (file: any["fileList"][number]) => {
		if (!file.url && !file.preview) {
			file.preview = (await getBase64(file.originFileObj)) as string;
		}
		previewImage.value = file.url || file.preview;
		previewVisible.value = true;
		previewTitle.value = file.name || file.url.substring(file.url.lastIndexOf("/") + 1);
	};

	const handleRemove = (file: any) => {
		deleteFile.push(file.name);
		const index = fileList.value.indexOf(file);
		const newFileList = fileList.value.slice();
		newFileList.splice(index, 1);
		fileList.value = newFileList;
	};

	const beforeUpload = async (file: any) => {
		newUploads.pop(newUploads.length);
		if (!formData.celebrity_profiles || !Array.isArray(formData.celebrity_profiles)) {
			formData.celebrity_profiles = [];
		}
		fileList.value = [...fileList.value, file];
		newUploads.push(file);
		return false;
	};
</script>
